<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add Shipping Town</p>
        <v-row>
          <v-col cols="12" md="3" class="py-0 my-1">
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              v-model="shipping_town"
              outlined dense ref="shipping_town"
              label="Shipping Town"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-0 my-1">
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addShippingTown()"
        >
          Add Shipping Town
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      shipping_town: "",
    }
  },
  mounted(){
    document.title = "Manager: Market Place | Add Town"
  },

  methods:{
    async addShippingTown(){
        if(this.shipping_town === ""){
            this.$store.commit('setSnackBarMessage', "Please input shipping town.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.shipping_town.focus()
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('shipping_town', this.shipping_town)

            await axios
                .post('/api/v1/manager/market_place/add/shipping/town', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.push('/marketplace/orders')
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },
  },
}
</script>
